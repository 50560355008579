<template>
  <div class="">
    <iframe :src="qrCodeHtml" width="100%" height="400" frameborder="0"></iframe>
  </div>
</template>
<script>
import {
  getSubmitPayOrder2,
} from "@/api/user.js"
export default {
  name: "alipayPage",
  data () {
    return {
      payForm: {
        orderNo: "",
        payType: "54",
        mixFlag: "0",
      },
      qrCodeHtml: null
    }
  },
  computed: {

  },
  async mounted () {
    console.log('this.$route.query',this.$route.query);
    
    if (this.$route.query.orderNo && this.$route.query.mixFlag) {
      this.payForm = { ...this.$route.query, payType: "54", }
      await this.getData()
    }
  },
  methods: {
    async getData () {
      let appSuccessCallBack = window.location.origin + "/payResult?type=success&pay=1&payType=" +
        this.payForm.payType +
        "&orderId=" +
        this.payForm.orderNo +
        "&price=" +
        this.$route.query.balance
      let appFailCallback = window.location.origin + "/payResult?type=fail" +
        "&orderId=" +
        this.payForm.orderNo +
        + '&pay=1' + "&price=" +
        this.$route.query.balance +

        "&message=訂單失效"
      const res = await getSubmitPayOrder2({ ...this.payForm, appSuccessCallBack, appFailCallback })
      if (res.resultID === 1200) {
        const blob = URL.createObjectURL(new Blob([res.data.qrCodeHtml], { type: 'text/html' }))
        this.qrCodeHtml = blob
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>